import React, { useRef, useMemo } from "react";
import { Box } from "@material-ui/core";
import PropTypes from "prop-types";
import styled from "styled-components";
import i18n from "i18next";
import Topbar from "./Topbar";

interface Props {
  children?: any;
}

const Layout: React.FC<Props> = ({ children }) => {
  const bodyRef = useRef<any>();
  useMemo(() => i18n.changeLanguage(localStorage.getItem("lang") ?? "en"), [])
  return (
    <StyledContainer>
      <Topbar />
      <Body component="main" {...{ ref: bodyRef }}>
        {children}
      </Body>
    </StyledContainer>
  );
};

const StyledContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  min-height: 100%;
  position: relative;
  max-width: 100rem;
  margin-left: auto;
  margin-right: auto;
`;

const Body = styled(Box)`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 7rem 3rem 2rem 3rem;
`;

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
