import { useContext, useState } from "react";
import styled from "styled-components";
import Button from "components/Button";
import { Box } from "@material-ui/core";
import { Trans, useTranslation } from "react-i18next";
import { useCookies } from "react-cookie";
import { Context } from "Provider";
import { CLASSID, COLOR1, COLOR3, COLOR4 } from "config";
import Spinner from "components/Spinner";

const ethers = require("ethers");

export default function Mint({ setPage }: any) {
  const { t } = useTranslation();
  const { mintContract, setMinted } = useContext(Context);
  const [cookies, setCookie] = useCookies(["minted"]);
  const [user, setUser] = useState("");
  const [doing, setDoing] = useState(false);

  const change = (e: React.ChangeEvent<HTMLInputElement>) => setUser(e.target.value);

  const mint = async () => {
    if (mintContract) {
      if (ethers.utils.isAddress(user)) {
        setDoing(true);
        try {
          if (cookies.minted) alert(t("msg.mintexist"));
          else {
            try {
              await mintContract.mintNFT(CLASSID, user);
              setCookie("minted", true);
              setMinted(true);
              setPage((x: number) => (x += 1));
              alert(t("msg.mintsuccess"));
            } catch (e) {
              console.log(e);
              setUser("");
              alert(t("msg.mintfail"));
            }
          }
        } catch (e) {
          console.log(e);
          setUser("");
        }
        setDoing(false);
      } else alert(t("msg.addresswrong"));
    } else alert(t("msg.nocontract"));
  };

  return (
    <StyledContainer component="section">
      {!cookies.minted && <Title>{t("mint.title")}</Title>}
      {cookies.minted && (
        <Subtitle>
          <Trans i18nKey="mint.warning">{[<Warning key={0}> </Warning>]}</Trans>
        </Subtitle>
      )}
      {!cookies.minted && (
        <>
          <Subtitle>{t("mint.paste")}</Subtitle>
          <Box>
            <input type="text" value={user} onChange={change} placeholder={t("mint.addr")} />
          </Box>
          <Button onClick={mint} disabled={doing || user === ""}>
            {doing && <Spinner />}
            {t("mint.mint")}
          </Button>
        </>
      )}
    </StyledContainer>
  );
}

const Warning = styled.span`
  color: ${COLOR4};
`;

const Subtitle = styled.p`
  color: ${COLOR3};
  text-align: center;
  margin-bottom: 0.5rem;
`;

const Title = styled.p`
  color: ${COLOR1};
  font-size: 1.25rem;
  font-weight: 800;
  margin-bottom: 2rem;
`;

const StyledContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${COLOR3} !important;
  > div:first-of-type {
    display: flex;
    justify-content: center;
    align-items: center;
    > input {
      border: none;
      outline: none;
      padding: 12px;
    }
  }
`;
