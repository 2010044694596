import styled from "styled-components";
import { Box } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { COLOR1, COLOR3 } from "config";

export default function Check() {
  const { t } = useTranslation();

  return (
    <StyledContainer component="section">
      <Subtitle>{t("check.description")}</Subtitle>
    </StyledContainer>
  );
}

const Subtitle = styled.p`
  color: ${COLOR1};
`;

const StyledContainer = styled(Box)`
  text-align: center;
  color: ${COLOR3} !important;
  > div:first-of-type {
    margin-top: 2rem;
  }
  >p {
    margin: auto;
    max-width: 80%;
  }
`;
