import { useContext, useMemo, useState } from "react";
import styled from "styled-components";
import { Box } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { CLASSID, COLOR1, COLOR3 } from "../config";
import { BigNumber } from "ethers";
import { ROUTERS } from "contracts";
import { Context } from "Provider";

const ethers = require("ethers");
const provider = new ethers.providers.JsonRpcProvider("https://rpc.callisto.network");

export default function Introduce() {
  const { t } = useTranslation();
  const { mintContract } = useContext(Context);
  const [image, setImage] = useState("");
  const [NFTLeft, setNFTLeft] = useState("--");

  useMemo(async () => {
    const _nftContract = new ethers.Contract(ROUTERS.NFT.address, ROUTERS.NFT.abi, provider);
    const _property = await _nftContract.getClassProperty(CLASSID, 0);
    const { image_ipfs } = JSON.parse(_property);
    setImage(image_ipfs);

    if (document.getElementById("nft_image")) {
      (document.getElementById("nft_image") as any).src = image_ipfs;
      (document.getElementById("nft_image") as any)?.play();
    }
  }, []);

  useMemo(async () => {
    if (mintContract) {
      const _res = await mintContract.classMinters(CLASSID);
      const _left = BigNumber.from(_res.max_amount).sub(BigNumber.from(_res.amount_minted));
      setNFTLeft(_left.toString());
    }
  }, [mintContract]);

  return (
    <StyledContainer component="section">
      <Title>
        {t("introduce.nftleft")}: {NFTLeft}
      </Title>
      <video controls autoPlay loop muted width="200" id="nft_image">
        <source src={image} type="video/mp4" />
      </video>
      <Title>{t("introduce.title")}</Title>
      <Subtitle>{t("introduce.description")}</Subtitle>
    </StyledContainer>
  );
};

const Title = styled.p`
  font-weight: 700;
  color: ${COLOR1};
`;

const Subtitle = styled.p`
  color: ${COLOR3};
`;

const StyledContainer = styled(Box)`
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  > video {
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
    margin-top: 1rem;
    margin-bottom: 2rem;
    display: block;
  }
  > p {
    margin-top: 0.5rem;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    @media (min-width: 1024px) {
      max-width: 70%;
    }
  }
  > p:first-of-type {
    font-size: 1.25rem;
    font-weight: 800;
  }
`;