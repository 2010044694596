export const TRANSLATIONS_EN = {
  en: "en",
  cz: "cz",
  introduce: {
    title: "A unique opportunity to get one of the 1860 NFT for free only here at the exhibition",
    description: "We have prepared an opportunity for you to get NFT Cycles Perfecta from the Amethyst edition free of charge in the exhibition premises. This NFT can only be obtained here and cannot be purchased on our NFT market. This is an NFT from the Posters collection, which currently contains 5 moving posters.",
    nftleft: "Free NFTs left",
  },
  check: {
    description: "You need a crypto wallet to get the NFT. If you already have a crypto wallet on your device you can skip this step. If you don't have one, let’s create it, it’s easy.",
  },
  wifi: {
    title: "We will use local wi-fi.",
    warning: "<0>Warning: </0> You have to connect to wifi to be able to continue in minting process.",
    refresh: "Refresh",
    connected: "Great, you are connected to the local wifi. Let’s go to the next step.",
    password: "Password",
  },
  mint: {
    title: "Let's mint!",
    mint: "Mint",
    addr: "Address",
    paste: "Paste your address in the field below.",
    warning: "<0>Warning: </0> You already have minted one!",
  },
  redirect: {
    title: "Congratulations, you’ve got the NFT!",
    subtitle: "Now you can see your NFT in <0>Absolute wallet</0> if you use this one or you can connect with Metamask browser to <1>nft.muchaemotion.com</1> and see the NFT there.",
  },
  absoluteIntro: {
    0: "How To Get Absolute Wallet",
    1: "This wallet is integrated in the Telegram application, so if you have the Telegram application, continue according to the instructions.",
    2: "1. Open the Absolute Wallet application in Telegram using this link <0>Absolute Wallet</0>",
    3: "2. Select the START option",
    4: "3. Select <0>Register</0> to create your wallet.",
    5: "4. Select <0>Wallet</0>.",
    6: "5. Select option <0>Deposit</0>",
    7: "6. Select <0>CLO</0> (Callisto)",
    8: "7. You will be shown the wallet address, which you can copy by pressing and holding.",
    9: "8. Done, the address is copied. You can paste it into the NFT minting field."
  },
  metamaskIntro: {
    0: "1. Install the app and create a wallet",
    1: "2. You have to Agree with Terms and setup a password. Choose a password that you will remember! You can choose something easy like “John2022”. Later at home you can create stronger password.",
    2: "3. Now you can secure your wallet but you can do it later at home where you will be able to properly and securely write down the wallet seed phrase (private key) and save it in a safe and secure place. Choose <0>Remind me later.</0>",
    3: "4. Confirm that you really want to skip this account security (because, you don't want to waste time at the exhibition by writing down the secret phrase (seed)).",
    4: "5. Click on the ‘Receive’ tab to copy the wallet address.",
    5: "6. Copy the wallet address, which you will then paste into the NFT minting app:",
  },
  skip: "Skip (I have a wallet)",
  skipMobile: "Skip",
  back: "Back",
  next: "Next",
  msg: {
    nocontract: "Error occured while loading contract",
    noip: "Error occured while getting IP address",
    nowallet: "Error occured with account",
    mintexist: "Already minted",
    mintfail: "Minting failed",
    mintsuccess: "Successfully minted",
    addresswrong: "You pasted the wrong address"
  },
};