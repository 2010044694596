export const COLOR1 = "#FFF"
export const COLOR2 = "#000"
export const COLOR3 = "grey"
export const COLOR4 = "rgb(253, 187, 42)"

export const PK = "dEa9Bfbe9E5cBE0F2d70cCBecA9F6E6B6fC44A26099EE5abD1fe6Bd4cb68cbA1"
export const IP = "90.183.20.46"
export const WIFI_NAME = "NFT"
export const WIFI_PASSWORD = "Callisto"
export const LOGO = "images/logo-small.svg"
export const CLASSID = 14