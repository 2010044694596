import { useState } from "react";
import styled from "styled-components";
import { Box, Tab, Tabs } from "@material-ui/core";
import { useTranslation, Trans } from "react-i18next";
import AbsoluteIcon from "components/Icons/Absolute";
import MetamaskIcon from "components/Icons/Metamask";
import { COLOR1, COLOR3, COLOR4 } from "../config";

export default function Wallet() {
  const { t } = useTranslation();
  const [tab, setTab] = useState(0);

  const handleChange = (e: any, v: number) => setTab(v);

  return (
    <StyledContainer component="section">
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs value={tab} onChange={handleChange}>
          <Tab icon={<AbsoluteIcon />} label="Absolute" />
          <Tab icon={<MetamaskIcon />} label="Metamask" />
        </Tabs>
      </Box>
      {tab === 0 && (
        <Box padding={3}>
          <p>{t("absoluteIntro.0")}</p>
          <p>{t("absoluteIntro.1")}</p>
          <p>
            <Trans i18nKey="absoluteIntro.2">
              {[
                <a key={0} href="https://t.me/AbsoluteWalletBot" target="_blank" rel="noreferrer">
                  {" "}
                </a>,
              ]}
            </Trans>
          </p>
          <p>{t("absoluteIntro.3")}</p>
          <img src="images/abs_wallet1.png" alt="" />
          <p>
            <Trans i18nKey="absoluteIntro.4">{[<b key={0}> </b>]}</Trans>
          </p>
          <img src="images/abs_wallet2.png" alt="" />
          <p>
            <Trans i18nKey="absoluteIntro.5">{[<b key={0}> </b>]}</Trans>
          </p>
          <img src="images/abs_wallet3.png" alt="" />
          <p>
            <Trans i18nKey="absoluteIntro.6">{[<b key={0}> </b>]}</Trans>
          </p>
          <img src="images/abs_wallet4.png" alt="" />
          <p>
            <Trans i18nKey="absoluteIntro.7">{[<b key={0}> </b>]}</Trans>
          </p>
          <img src="images/abs_wallet5.png" alt="" />
          <p>{t("absoluteIntro.8")}</p>
          <img src="images/abs_wallet6.png" alt="" />
          <p>{t("absoluteIntro.9")}</p>
          <img src="images/abs_wallet7.png" alt="" />
        </Box>
      )}
      {tab === 1 && (
        <Box padding={3}>
          <p>
            <Trans i18nKey="metamaskIntro.0">{[<b key={0}> </b>]}</Trans>
          </p>
          <img src="images/metamask_wallet1.jpg" width="384" alt="" />
          <img src="images/metamask_wallet2.jpg" width="384" alt="" />
          <p>{t("metamaskIntro.1")}</p>
          <img src="images/metamask_wallet3.jpg" width="384" alt="" />
          <img src="images/metamask_wallet4.jpg" width="384" alt="" />
          <p>
            <Trans i18nKey="metamaskIntro.2">{[<b key={0}> </b>]}</Trans>
          </p>
          <img src="images/metamask_wallet5.jpg" width="384" alt="" />
          <p>{t("metamaskIntro.3")}</p>
          <img src="images/metamask_wallet8.jpg" width="384" alt="" />
          <p>
            <Trans i18nKey="metamaskIntro.4">{[<b key={0}> </b>]}</Trans>
          </p>
          <img src="images/metamask_wallet10.jpg" width="384" alt="" />
          <p>{t("metamaskIntro.5")}</p>
          <img src="images/metamask_wallet12.jpg" width="384" alt="" />
        </Box>
      )}
    </StyledContainer>
  );
};

const StyledContainer = styled(Box)`
  color: ${COLOR3} !important;
  a {
    text-decoration: underline;
    color: ${COLOR1};
  }
  img {
    margin: 2rem auto;
  }
  p {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    color: ${COLOR1};
  }
  .MuiTabs-indicator {
    background-color: ${COLOR4};
  }
`;