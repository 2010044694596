import { createContext, useState } from "react";
import { Contract } from "ethers";

export const Context = createContext<any>(null);

export default function ContextProvider({ children }: any) {
  const [userIP, setUserIP] = useState("");
  const [minted, setMinted] = useState(false);
  const [mintContract, setMintContract] = useState<Contract>();
  return <Context.Provider value={{ mintContract, setMintContract, userIP, setUserIP, minted, setMinted }}>{children}</Context.Provider>;
}
