import React from "react";
import { Box } from "@material-ui/core";
import PropTypes from "prop-types";
import styled from "styled-components";
import { COLOR1, COLOR2, COLOR4 } from "config";

interface Props extends React.HTMLAttributes<HTMLButtonElement> {
  primary?: any;
  disabled?: boolean;
  children?: any;
}

const Button: React.FC<Props> = (props) => {
  const { primary = 0, children, ...mainProps } = props;
  return (
    <StyledContainer primary={primary}>
      <button {...mainProps}>{children}</button>
    </StyledContainer>
  );
};

const StyledContainer = styled(Box)<any>`
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  @media (min-width: 768px) {
    padding-top: 0.5rem;
  }
  @media (min-width: 768px) {
    padding-bottom: 0.5rem;
  }
  > button {
    border-radius: 9999px;
    background: ${({ primary }) => {
      if(primary === 0) return COLOR4;
      if(primary === 1) return COLOR1;
      if(primary === 2) return "transparent";
    }};
    color: ${({ primary }) => {
      if(primary === 0) return COLOR1;
      if(primary === 1) return COLOR2;
      if(primary === 2) return COLOR1;
    }};
    text-decoration-color: ${({ primary }) => {
      if(primary === 0) return COLOR1;
      if(primary === 1) return COLOR2;
      if(primary === 2) return COLOR1;
    }};
    transition: 0.5s;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    font-weight: 700;
    border: none;
    outline: none;
    display: flex;
    align-items: center;
    &:hover:not(:disabled) {
      text-decoration: underline;
    }
    &:disabled {
      cursor: not-allowed;
      opacity: ${({ primary }) => primary === 2 ? 0 : 0.5};
    }
    >div:first-of-type {
      margin-right: 8px !important;
    }
  }
`;

Button.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Button;
