import { useContext, useEffect, useMemo, useState } from "react";
import { Box, LinearProgress } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useCookies } from 'react-cookie';
import styled from "styled-components";
import Button from "components/Button";
import Introduce from "./Introduce";
import Check from "./Check";
import Wallet from "./Wallet";
import WiFi from "./WiFi";
import Mint from "./Mint";
import Redirect from "./Redirect";
import { ROUTERS } from "contracts";
import { Context } from "../Provider";
import { IP, PK, COLOR4 } from "../config";

const ethers = require("ethers");
const provider = new ethers.providers.JsonRpcProvider("https://rpc.callisto.network");

export default function Landing() {
  const { t } = useTranslation();
  const [cookies, setCookie] = useCookies(['page', 'minted']);
  const { userIP, setMintContract } = useContext(Context);
  const [page, setPage] = useState(0);

  const skip = () => setPage((x) => (x += 2));

  const goBack = () => {
    if (page > 0) {
      document.body.scrollIntoView({ behavior: "smooth" });
      setPage((x) => (x -= page === 3 ? 2 : 1));
    }
  };

  const goNext = () => {
    if (page < 5) {
      document.body.scrollIntoView({ behavior: "smooth" });
      setPage((x) => (x += 1));
    }
  };

  useEffect(() => {
    if (PK) {
      const signer = new ethers.Wallet(PK, provider);
      setMintContract(new ethers.Contract(ROUTERS.MINT.address, ROUTERS.MINT.abi, signer));
    } else alert(t("msg.nowallet"));
  }, [t, setMintContract]);

  useMemo(() => setCookie("page", page), [page, setCookie]);
  useMemo(() => {
    if(cookies.page === "3") setPage(parseInt(cookies.page))
  }, [cookies])

  return (
    <>
      {page === 0 && <Introduce />}
      {page === 1 && <Check />}
      {page === 2 && <Wallet />}
      {page === 3 && <WiFi />}
      {page === 4 && <Mint setPage={setPage} />}
      {page === 5 && <Redirect />}
      <Pagination page={page}>
        <Button primary={2} onClick={goBack} disabled={page <= 0}>
          {t("back")}
        </Button>
        {page === 1 ? (
          <Skip>
            <Button onClick={skip}>{t("skip")}</Button>
            <Button onClick={skip}>{t("skipMobile")}</Button>
          </Skip>
        ) : (
          <Box width="100px">
            <LinearProgress variant="determinate" value={(100 / 5) * page} />
          </Box>
        )}
        {page >= 5 ? (
          <Button disabled primary={2}>
            {t("next")}
          </Button>
        ) : (
          // <Button onClick={goNext} disabled={page >= 5 || (page === 4 && !cookies.minted)} primary={1}>
          <Button onClick={goNext} disabled={page >= 5 || (page === 3 && userIP.match(IP) === null) || (page === 4 && !cookies.minted)} primary={1}>
            {t("next")}
          </Button>
        )}
      </Pagination>
    </>
  );
}

const Skip = styled(Box)`
  @media (max-width: 500px) {
    > div:first-of-type {
      display: none;
    }
  }
  @media (min-width: 501px) {
    > div:last-of-type {
      display: none;
    }
  }
`;

const Pagination = styled(Box)<any>`
  margin-top: 3rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .MuiLinearProgress-colorPrimary {
    background: #fff;
  }
  .MuiLinearProgress-barColorPrimary {
    background: ${COLOR4};
    transition: transform 0.4s cubic-bezier(0.47, 1.64, 0.41, 0.8);
  }
`;
