import { useContext } from "react";
import styled from "styled-components";
import { Box } from "@material-ui/core";
import { Trans, useTranslation } from "react-i18next";
import { Context } from "Provider";
import { COLOR1, COLOR3 } from "config";
import Button from "components/Button";

export default function Redirect() {
  const { t } = useTranslation();
  const { minted } = useContext(Context);

  const openMetamask = () => window.open("https://dev--nft-muchaemotion-com.netlify.app/mynfts", "_blank");

  return (
    <StyledContainer component="section">
      {minted && (
      <Success>
        <img src="images/checkmark2.png" width="32" height="32" alt="" />
        <Title>{t("redirect.title")}</Title>
      </Success>
      )}
      <Subtitle>
        <Trans i18nKey="redirect.subtitle">
          {[
            <a key={0} href="https://t.me/AbsoluteWalletBot" target="_blank" rel="noreferrer">
              {" "}
            </a>,
            <a key={0} href="https://nft.muchaemotion.com" target="_blank" rel="noreferrer">
              {" "}
            </a>,
          ]}
        </Trans>
      </Subtitle>
      <Button onClick={openMetamask}>Metamask</Button>
    </StyledContainer>
  );
}

const Success = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-wrap: wrap;
  gap: 16px;
`;

const Title = styled.p`
  font-weight: 700;
  color: ${COLOR1};
`;

const Subtitle = styled.p`
  color: ${COLOR3};
  max-width: 70%;
  text-align: center;
  > a {
    color: white;
    text-decoration-color: white;
  }
  @media (max-width: 768px) {
    max-width: 100%;
  }
`;

const StyledContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${COLOR3} !important;
  > p:first-of-type {
    margin-top: 1rem;
    margin-bottom: 2rem;
    word-break: break-word;
  }
`;
