export const TRANSLATIONS_CZ = {
  en: "en",
  cz: "cz",
  introduce: {
    title: "Jedinečná příležitost získat jeden z 1860 NFT zdarma pouze zde na výstavě",
    description: "Připravili jsme pro vás možnost získat v prostorách výstavy zdarma NFT Cycles Perfecta z edice Ametyst. Toto NFT lze získat pouze zde a nelze jej zakoupit na našem NFT trhu. Toto je NFT z kolekce Posters, která aktuálně obsahuje 5 pohyblivých plakátů.",
    nftleft: "Zbývají bezplatné NFT",
  },
  check: {
    description: "K získání NFT potřebujete kryptopeněženku. Pokud již máte na svém zařízení kryptopeněženku, můžete tento krok přeskočit. Pokud žádný nemáte, pojďme si ho vytvořit, je to snadné.",
  },
  wifi: {
    title: "Využijeme místní wi-fi.",
    warning: "<0>Varování: </0> Abyste mohli pokračovat v procesu ražby, musíte se připojit k wifi.",
    refresh: "Obnovit",
    connected: "Skvělé, jste připojeni k místní wifi. Pojďme k dalšímu kroku.",
    password: "Heslo",
  },
  mint: {
    title: "Pojďme mát!",
    mint: "Máta",
    addr: "Adresa",
    paste: "Vložte svou adresu do pole níže.",
    warning: "<0>Varování: </0> Jeden už máte vyražený!",
  },
  redirect: {
    title: "Gratulujeme, máte NFT!",
    subtitle: "Nyní můžete vidět svou NFT v <0>Absolute peněžence</0>, pokud používáte tuto, nebo se můžete připojit pomocí prohlížeče Metamask na <1>nft.muchaemotion.com</1> a vidět NFT tam.",
  },
  absoluteIntro: {
    0: "Návod na pořízení Absolute Wallet",
    1: "Tato peněženka je integrovaná v aplikaci telegram, pokud tedy máte aplikaci Telegram, pokračujte dle návodu.",
    2: "1. Otevřete aplikaci Absolute Wallet v Telegramu pomocí tohoto odkazu <0>Absolute Wallet</0>",
    3: "2. Zvolte možnost START",
    4: "3. Vyberte možnost <0>Registrovat</0>, aby se Vám peněženka založila.",
    5: "4. Vyberte možnost <0>Peněženka</0>.",
    6: "5. Vyberte možnost <0>Vklad</0>",
    7: "6. Vyberte možnost <0>CLO</0> (Callisto)",
    8: "7. Zobrazí se Vám adresa peněženky, kterou můžete podržením zkopírovat.",
    9: "8. Hotovo, adresa je zkopírována. Můžete ji vložit do pole pro vymintování NFT."
  },
  metamaskIntro: {
    0: "1. Nainstalujte aplikaci a vytvořte peněženku pomocí <0>Create a new wallet</0> tlačítka",
    1: "2. Musíte odsouhlasit podmínky používání aplikace a nastavit si heslo pro přístup do aplikace. Zvolte takové heslo, které si budete pamatovat, něco jednoduchého jako například “John2022”. Později si doma můžete heslo změnit na silnější.",
    2: "3. Nyní můžete zálohovat svoji peněženku tím, že si zapíšete vygenerovaný klíč (seed) na papír. Toto můžete později udělat v klidu doma, proto tento krok nyní přeskočte tím, že kliknete na <0>Remind me later</0>",
    3: "4. Potvrďte, že opravdu chcete přeskočit zálohování (protože se nechcete zdržovat na výstavě zapisováním kódu na papír).",
    4: "5. Klikněte na tlačítko <0>Receive</0>",
    5: "6. Zkopírujte adresu peněženky, kterou poté zadáte do aplikace na získání NFT zdarma",
  },
  skip: "Přeskočit (mám peněženku)",
  skipMobile: "Přeskočit",
  back: "Zadní",
  next: "Další",
  msg: {
    contract: "Při načítání smlouvy došlo k chybě",
    ip: "Při získávání IP adresy došlo k chybě",
    nowallet: "U účtu došlo k chybě",
    mintexist: "Již vyraženo",
    mintfail: "Ražba se nezdařila",
    mintsuccess: "Úspěšně vyraženo",
    addresswrong: "Vložili jste špatnou adresu"
  },
};