import React, { useState } from "react";
import styled from "styled-components";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import { Box, Fade } from "@material-ui/core";
import { Language } from "@material-ui/icons";
import { LOGO, COLOR1, COLOR2 } from "../config"

export default function Topbar() {
  const { t } = useTranslation();
  const [openLang, setOpenLang] = useState(false);
  const [currentLang, setCurrentLang] = useState(localStorage.getItem("lang") ?? "en");
  const clickLang = (i: number) => {
    const strs = ["en", "cz"];
    i18n.changeLanguage(strs[i]);
    setCurrentLang(t(strs[i]));
    setOpenLang(false);
    localStorage.setItem("lang", strs[i]);
  };
  return (
    <StyledContainer>
      <Box display="flex">
        <img src={LOGO} alt="" />
        <PCMenu>
          <ul>
            <li>
              <span
                style={{ cursor: "pointer" }}
                onClick={() => setOpenLang(!openLang)}
              >
                <Language fontSize="small" />{currentLang}
              </span>
              <Fade in={openLang}>
                <LangBox>
                  <Box onClick={() => clickLang(0)}>{t("en")}</Box>
                  <Box onClick={() => clickLang(1)}>{t("cz")}</Box>
                </LangBox>
              </Fade>
            </li>
          </ul>
        </PCMenu>
      </Box>
    </StyledContainer>
  );
};

const LangBox = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: absolute;
  top: calc(100% + 20px);
  right: 0;
  background: ${COLOR2};
  border: 1px solid grey;
  color: ${COLOR1};
  box-sizing: content-box;
  width: max-content;
  line-height: 24px;
  > div {
    cursor: pointer;
    padding: 4px 16px;
    transition: 0.3s;
    width: 100%;
    &:hover {
      background: grey;
    }
  }
`;

const PCMenu = styled(Box)`
  > ul {
    margin: 0;
    padding: 0;
    > li {
      list-style: none;
      border-bottom: 1px solid ${COLOR1};
      line-height: 3;

      display: inline-block;
      border-bottom: none;
      margin-right: 0.5rem;
      >span {
        display: flex;
        align-items: center;
        >svg {
          margin-right: 4px;
        }
      }
    }
    > li a {
      color: ${COLOR1};
    }
    > li:first-of-type {
      color: ${COLOR1};
      text-transform: uppercase;
      position: relative;
      > span:hover {
        cursor: pointer;
        text-decoration: underline;
        text-decoration-color: ${COLOR1};
      }
    }
  }
  > button {
    position: absolute;
    top: 0;
    left: 1rem;
    background: transparent url(/images/menu-burger.svg) 50% no-repeat;
    background-size: 3rem;
    width: 3rem;
    height: 5rem;
    padding: 1rem 0;
    border: none;
    @media print, screen and (min-width: 68em) {
      display: none;
    }
    @media print, screen and (min-width: 40em) {
      padding: 1rem 0;
      right: 2rem;
    }
    > div {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      position: absolute;
      top: calc(100% + 20px);
      left: 0;
      border: 1px solid grey;
      color: ${COLOR1};
      box-sizing: content-box;
      width: max-content;
      > div {
        cursor: pointer;
        padding: 4px 16px;
        transition: 0.3s;
        background: ${COLOR2};
        width: 100%;
        text-align: left;
        &:hover {
          background: grey;
        }
      }
    }
  }
`;

const StyledContainer = styled(Box)`
  position: absolute;
  z-index: 1;
  width: 100%;
  > div {
    justify-content: space-between;
    align-items: center;
    padding: 1rem 3rem;
    >img {
      height: 32px;
    }
  }
`;