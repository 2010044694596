import { useContext, useMemo } from "react";
import styled from "styled-components";
import axios from "axios";
import { Box } from "@material-ui/core";
import { useTranslation, Trans } from "react-i18next";
import { IP, COLOR1, COLOR3, COLOR4 } from "config";
import { Context } from "Provider";
import Button from "components/Button";

export default function WiFi() {
  const { t } = useTranslation();
  const { userIP, setUserIP } = useContext(Context);

  useMemo(async () => {
    if (IP) {
      try {
        const { data } = await axios.get("https://api.ipify.org/?format=json");
        setUserIP(data.ip);
      } catch (e) {
        console.log(e);
        alert(t("msg.noip"));
      }
    } else alert(t("msg.noip"));
  }, [setUserIP, t]);

  return (
    <StyledContainer component="section">
      {!IP || userIP.match(IP) === null ? (
      // {!IP ? (
        <>
          <Title>{t("wifi.title")}</Title>
          <Box component="p" color={COLOR3}>
            <Trans i18nKey="wifi.warning">{[<Warning key={0}> </Warning>]}</Trans>
          </Box>
          <Box component="p" color="white">Wifi: NFT</Box>
          <Box component="p" color="white">{t("wifi.password")}: Callisto</Box>
          <Button onClick={() => (document.location.href = "/")} primary={1}>
            {t("wifi.refresh")}
          </Button>
        </>
      ) : (
        <Success>
          <img src="images/checkmark2.png" width="32" height="32" alt="" />
          <Title>{t("wifi.connected")}</Title>
        </Success>
      )}
    </StyledContainer>
  );
}

const Success = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-wrap: wrap;
  gap: 16px;
`

const Warning = styled.span`
  color: ${COLOR4};
`;

const Title = styled.p`
  font-weight: 700;
  color: ${COLOR1};
`;

const StyledContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${COLOR3} !important;
  > p:first-of-type {
    font-size: 1.25rem;
    font-weight: 800;
    margin-bottom: 2rem;
  }
  >p:nth-of-type(2) {
    text-align: center;
  }
  >p:nth-of-type(3) {
    margin-top: 2rem;
  }
  > div:first-of-type {
    margin-top: 2rem;
  }
`;
