import MINT_ABI from './MINT_ABI.json'
import NFT_ABI from './NFT_ABI.json'

export const ROUTERS = {
    MINT: {
        abi: MINT_ABI,
        address: '0xd09c8De30277A4B1c34f806636BDEEbC1ee38beB',
    },
    NFT: {
        abi: NFT_ABI,
        address: '0xd0725743f665B8041DcfbB0c4B55bbC3679Fb033',
    },
}