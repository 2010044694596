import React from 'react'
import styled from 'styled-components'
import { Box } from "@material-ui/core";

const Spinner: React.FC = () => {
    return (
        <StyledContainer>
            <svg className='spinnerInner' viewBox='0 0 120 120'>
                <circle cx='60' cy='60' r='50' />
            </svg>
        </StyledContainer>
    );
}

const StyledContainer= styled(Box)`
    @keyframes inner {
        0% {
            stroke-dashoffset: 187;
        }

        25% {
            stroke-dashoffset: 80;
        }

        100% {
            stroke-dashoffset: 187;
            transform: rotate(360deg);
        }
    }

    >svg {
        transform: rotate(-90deg);
        stroke-linecap: round;
        stroke-width: 25;
        fill: none;
        overflow: hidden;
        width: 20px;
        height: 20px;
        >circle:first-of-type {
            stroke-dashoffset: 0;
            transform-origin: center center;
            stroke-dasharray: 312;
            animation: 1s linear 0s infinite normal none running inner;
            stroke: white;
        }
    }
`

export default React.memo(Spinner)